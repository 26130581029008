import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import MigrationIcon from '../../images/services-data-migration.svg'
import DevopsIcon from '../../images/services-devops.svg'
import TrainingIcon from '../../images/services-training.svg'
import IntegrationIcon from '../../images/services-integration.svg'
import styled, { css } from 'styled-components';


const ICON_SIZE = 50;
const StyledMigrationIcon = styled(MigrationIcon)`
  fill: ${(props) => props.theme.primary};
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
`;
const StyledTrainingIcon = styled(TrainingIcon)`
  fill: ${(props) => props.theme.primary};
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
`;
const StyledIntegrationIcon = styled(IntegrationIcon)`
  fill: ${(props) => props.theme.primary};
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
`;
const StyledDevopsIcon = styled(DevopsIcon)`
  fill: ${(props) => props.theme.primary};
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
`;

const Icon = ({label}) => {
    console.log(label)
    switch(label) {
        case 'migration':
            return (<StyledMigrationIcon/>);
        case 'training':
            return (<StyledTrainingIcon/>);
        case 'integration':
            return (<StyledIntegrationIcon/>);
        case 'devops':
            return (<StyledDevopsIcon/>);
        default:
            return (<StyledMigrationIcon/>);
    }
}

export default function BasicCard({ title, description, target, icon }) {
    return (
        <Card sx={{ p: 2 }}>
            <CardContent sx={{ pb: 8, minHeight: 460 }}>
                <Icon label={icon}/>
                <Typography variant="h5" component="div" gutterBottom sx={{mt: 2}}>
                    <strong>{title}</strong>
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {description}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" href={target}>Learn More</Button>
            </CardActions>
        </Card>
    );
}
